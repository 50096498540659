.user-modal {
    position: relative;

    .user-modal__left {
        @include up(1100) {
            height: calc(75vh - 50px);
            position: sticky;
            top: 0;
        }
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr 0.5fr;

        .user-infos {
            background-color: var(--color-blue);
            color: white;
        }
        
        .user-company {
            p {
                font-size: 28px;
                font-weight: 500;
                color: var(--color-blue);
            }
            .user-company__logo img {
                max-width: 300px;
            }
            
        }
        
        .user-country {
            text-transform: uppercase;
            background-color: var(--color-blue);
            color: white;
        }
        .vineyard-label{
            width: 50px;
            position: absolute;
            right: 20px;
        }
    }

    .user-modal__right {
        @include up(1100) {
            overflow: auto;
        }

        .user-modal__right__social {

            .btn-social {
                width: 50px;
            }
        }
        
        .user-modal__right__footer {
            position: sticky;
            bottom: 0;
            background: var(--color-blue);
            color: white;
            width: 100%;

            img {
                width: 75px;
                margin-left: 10px;
            }
        }
    }
}