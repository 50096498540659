table,
.table {
  width: 100%;
  line-height: 1.2;
}
.table thead th {
  font-weight: 300;
  text-transform: uppercase;
  opacity: 0.6;
  font-size: 0.9em;
  text-align: left;
}
.table thead th svg {
  opacity: 1;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  display: inline-block;
}
.table .desc svg {
  transform: scaleY(-1);
}
.table thead th:last-child {
//   text-align: right;
}
.table td {
    vertical-align: middle;
    padding: space(2) space(2) space(2) 0;
    width: 100px !important;
}
.table td:last-child {
  padding-right: 0 !important;
}
.table tbody tr {
  border-bottom: 1px solid var(--border);
}
.table a:not([class^="btn"]) svg, .table button:not([class^="btn"]) svg {
  display: block;
  width: 16px;
  height: 16px;
  opacity: 0.15;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }
}
.table a:not([class^='btn']):hover {
  color: var(--contrast);
}
.table tbody > a {
  display: table-row;
}

.table.table-with-border-line {
  tr, th{
    border: 1px solid var(--border);
  }

  p{
    padding: 0;
    margin: 0;
  }

}