.page-header-error {
  position: relative;
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 60px;

}

.page-header-error img {
  width: 300px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  @include down(650) {
    display: none;
  }
}
