// Variables
// ==================
$font: arial, sans-serif;
$font-alt: arial, sans-serif;

// Font face
// ==================
%card-title {
  font-family: $font-alt;
  font-size: 20px;
  font-weight: bold;
}

h1 {
  font-family: $font-alt;
  font-weight: 700;
  font-style: normal;
  font-size: 30px;
  font-size: 2rem;
  line-height: 1.2;
  color: var(--color-blue);

  @include up(600) {
    font-size: 36px;
    font-size: 1.8rem;
  }
}

h2 {
  font-style: normal;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.6rem;
  margin-bottom: 1rem;
  color: var(--color-title-2);
}

.home_side_blocs h2 {
  color: var(--color-title-2);
}

%underlined {
  border-bottom: 1px solid var(--border);
  padding-bottom: space(1);
  margin-bottom: space(3);
}

h3 {
  font-weight: 700;
  font-style: normal;
  font-size: 1.4rem;
  line-height: 2rem;
//   padding-top: 2rem;
  margin-bottom: 1rem;
  color: var(--color-title-3);

  strong {
    font-weight: bold;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}

h4 {
  display: block;
  font-family: $font-alt;
  font-size: 1rem;
  line-height: 1.2rem;
//   padding-top: 2rem;
  padding-bottom: 0;
  margin-bottom: 1rem;
}

h5 {
  font-family: $font-alt;
  font-weight: bold;
  font-size: 1rem;
}

p {
  padding-bottom: 0.8rem;
  font-size: 16px;
  font-weight: 500;
}

ul {

}

hr {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.container li {
  margin-left: 40px;
  padding-left: 20px;
  list-style-type: disc;
}

a {
  color: var(--color-link);
  text-decoration: underline;
}

small {
  font-size: .9em;
}

.dotted {
    border-bottom: dotted 2px var(--color-blue);
}

// STYLES FOOTER
// ==================

.footer a {
  font-size: 0.8rem;
  font-weight: 500;
  color: rgba($color: black, $alpha: 0.7);
}

// Pour générer les polices : https://transfonter.org/

// Alignements
// ==================
.text-left {
  text-align: left!important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-inversed {
  color: var(--color-inversed);
}
.normal {
  font-weight: normal;
}
.bold {
  font-weight: bold;
}
.uppercase {
  text-transform: uppercase;
}
.underline {
  text-decoration: underline;
}
a.underline:hover {
  text-decoration: none;
}
.reader-only {
  display: none;
}
.text-danger {
  color: var(--red);
}
.text-muted {
  color: var(--color-light);
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-big {
  font-size: 1.1em;
}
.ignore-br br {
  display: none;
}
.link {
  transition: color .3s;
}
.link:hover {
  color: var(--contrast);
  text-decoration: underline;
}
.link-as-block a{display: block}
.vertical-align-middle td{vertical-align: middle}