.rounded {
  border-radius: 50%;
}

.shadow {
  box-shadow: 0 0 20px var(--shadow);
}

.card,
%card {
  background: var(--card-bg, var(--background-light));
//   border: 1px solid var(--border);
  box-shadow: 0 2px 4px var(--shadow);
  box-shadow: 0 0 20px var(--shadow);
  border-radius: 12px;
  transition: 0.3s;
}

.card-big,
%card-big {
  background: var(--card-bg, var(--background-light));
  border: 1px solid var(--border);
  box-shadow: 0 0 20px var(--shadow);
  border-radius: 12px;
}

%card-hoverable {
  &:hover {
    border-color: var(--contrast);
    box-shadow: 0 0 8px var(--shadow);
  }
}

.card .icon {
  vertical-align: middle;
  transform: translateY(-2px);
}

// Carte
// =============
.card {
  position: relative;
  display: flex;
  flex-direction: column;
}
.card__body {
  padding: var(--card-padding);
}
.card__icons {
  display: flex;
  flex: none;
  align-items: center;
}
.card__description p {
  color: var(--color-light)
}
.card__title {
  font-family: $font-alt;
  line-height: 1.3;
  font-size: 16px;
  font-weight: bold;
}
.card__icons > * + * {
  margin-left: space(1);
}
.card__icons img {
  width: 40px;
  height: 40px;
  display: block;
  object-fit: contain;
}
.card__footer {
  margin-top: auto;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  display: flex;
  align-items: center;
  min-height: 36px;
  background: var(--card-bg-footer, var(--card-footer));
  padding: 0 var(--card-padding);
  font-size: .9rem;

  .card__progress ~ & {
    margin-top: 0;
  }

  & > * {
    color: var(--color-light);
    white-space: nowrap;
  }

  & > * {
    // margin-right: space(2);
  }

  & > *:last-child {
    // margin-right: 0;
    // margin-left: auto;
  }
}
.card a {
  position: relative;
  z-index: 3;
}
.card__link {
  display: block;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2 !important;
  border: 1px solid transparent;
  transition: 0.3s;
  border-radius: 12px;
  cursor: pointer;
}
.card__link:hover {
  border: 1px solid var(--contrast);
}
.card__progress {
  opacity: 0;
  margin-top: auto;
  background: var(--border);
  span {
    width: calc(var(--progress, 0) * 100%);
    height: 3px;
    display: block;
    background: var(--contrast);
  }
}
[style^='--progress'] .card__progress {
  opacity: 1!important;
}
.card--stacked {
  &::after, &::before {
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transition: .3s;
    @extend %card;
  }
  &::after {
    transform: translate(6px, 6px);
  }
  &::before {
    transform: translate(0px, 0px);
  }
  &:hover {
    &::after {
      transform: translate(6px, 6px) rotate(3deg);
    }
    &::before {
      opacity: 1;
      transform: translate(0px, 0px) rotate(-3deg);
    }
  }
}

// Complété
// =============
.card.is-completed,
.is-completed .card{
  position: relative;
  box-shadow: 0 0 0 4px var(--contrast);
}
.card.is-completed::after,
.is-completed .card::after {
  content: '';
  position: absolute;
  top: 7px;
  right: 4px;

  width: 17px;
  height: 13px;
background: url(/assets/images/check.svg);
}
.card.is-completed::before,
.is-completed .card::before {
  content: '';
  width: 0;
  height: 0;

  position: absolute;
  top: -1px;
  right: -1px;
  border-left: solid 48px transparent;
  border-top: solid 48px var(--contrast);
}

// Illustration
// =============
.card__illustration {
  display: none;
  width: 250px;
  position: relative;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#121c42, 0.65);
  }

  &::after {
    content: attr(data-text);
    position: absolute;
    top: calc(50% - 44px);
    left: 0;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-family: $font-alt;
    color: #fff;
  }

  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    transform: translate(-50%, -50%);
    background: url(/assets/images/play.svg) center center no-repeat;
  }
}

@include up(600) {
  .card-illustration {
    display: grid;
    grid-template-columns: 1fr 250px;
    grid-gap: 30px;

    .homepage & {
      min-height: 340px;
    }
  }
  .card__illustration {
    display: block;
  }
}

