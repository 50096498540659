// Alerts
// ==================
.alert {
  @extend %card;
  margin: space(3) auto;
  padding: space(2);
  width: 100%;
  display: flex;
  align-items: center;
  animation: alertIn 0.5s both;
  line-height: 1.2;
  position: relative;
  border-bottom: 3px solid var(--border);
  color: var(--color);

  a {
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
}
.alert strong {
  font-weight: bold;
}
.alert.out {
  animation: alertOut 0.5s both;
}
.alert > svg:first-child {
  flex: none;
  width: 30px;
  height: 30px;
  margin-right: space(2);
}
.alert .alert-close {
  width: 30px;
  flex: none;
  margin-left: auto;
  margin-right: 0;
  color: var(--color);
  opacity: 0.3;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
}
.alert-close svg {
  width: 12px;
  height: 12px;
  color: var(--color) !important;
}
.alert-danger {
  border-bottom-color: var(--red);

  svg:first-child {
    color: var(--red);
  }
}
.alert-success {
  border-bottom-color: var(--green);

  svg:first-child {
    color: var(--green);
  }
}
.alert-info {
  border-bottom-color: var(--contrast);

  svg:first-child {
    color: var(--contrast);
  }
}
.alert-warning {
  border-bottom-color: var(--yellow);

  svg:first-child {
    color: var(--yellow);
  }
}

.alert__progress {
  height: 3px;
  background: var(--background-light);
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 100%;
  z-index: 3;
  transform-origin: 0 100%;
  animation: alertDuration 2s linear both;
}

@keyframes alertIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes alertOut {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
@keyframes alertDuration {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

.dashboard .alert,
.is-floating .alert,
.alert.is-floating {
  box-shadow: 0 0 20px var(--shadow);
  position: fixed;
  top: 20px;
  right: 20px;
  max-width: 400px;
  z-index: 3000;
}

.formatted .alert {
  display: block;
  border-top: var(--border) solid 1px;
  border-right: var(--border) solid 1px;
  border-left: var(--border) solid 1px;
}

// Bullets
// ==================
.bullet {
  display: block;
  width: 6px;
  border-radius: 6px;
  height: 6px;
  margin-left: auto;
  margin-right: auto;
  background: #23db72;
}
.bullet--danger {
  background: #fb4635;
}
