// Variables
// ==================
:root {
    --color-blue: #eab205;
    --color-blue-light: #205831;
    --color-blue-light-secondary: #737544;
    --color-green: #599220;
    --color-dark: #000;
    --color-title-2: #0a381c;
    --color-title-3: #78836c;
    --color: #000000;

    --color-dark: #{rgba(#0a381c, 0.8)};
    --color-light: #242014;
    --color-td: #{rgba(#0a381c, 0.2)};
    --color-td-grey: #{rgba(#1d6331, 0.2)};
    --color-td-light: #{rgba(#0a381c, 0.1)};
    --color-td-grey-light: #{rgba(#eeeeee, 0.1)};
    --color-link: #0a381c;
    --color-inversed: #effbec;
    --color-inversed60: #effbec99;
    --color-transparent: #{rgba(#0a381c, 0)};
    --contrast: #0a381c;
    --contrast25: #737544;
    --gap: 20px;
    --space: 8px;
    --border: #d5e3ec;
    --border-light: #e6eff4;
    --background: #f7fafb;
    --background-input:#dbe8e8;
    --border-input:#78836c;
    --background-light: #fff;
    --shadow: #d8e1e8;
    --shadow: rgba(0, 0, 0, 0.2);
    --list-hover: var(--background);
    --play: #f3f9fb; // Couleur de fond du bouton play
    --space-section: 150px;
    --card-footer: #f7fafd;
    --card-padding: 16px;
    --header-height: 137px;
    --red: #f25353;
    --green: #7aa797;
    --yellow: #feb32b;
    --yellow-alpha: #feb32b4c;
    --skeleton: var(--border-light);
    --skeleton-wave: var(--border-light);
    --selection-background: var(--border);
    --header-background: #FFFFFFF2;
    --header-background-inversed: #0a381c;
}
  
// Outils
// ==================
@import 'tools/reset';
@import 'tools/functions';
@import 'tools/mixins';
@import 'tools/layout';
@import 'tools/typography';
@import 'tools/animations';
  
// Generic
// ==================

html {
    font-family: $font;
    font-size: 16px;
}

body {
    color: var(--color);
    line-height: 1.6;
    background: var(--background);
    scroll-margin: 100px 0 0 0;
    word-break: break-word;
    overflow: hidden auto;
}

body.header-light::after {
    // display: none;
}
.text-uppercase {
    text-transform: uppercase;
}
::selection {
    background: var(--contrast);
    color: #fff;
}
label.legend {    
    text-transform: uppercase;
    font-weight: bold;
    color: var(--color-title-2);
    font-size: 16px;
}
.fc .fc-list-event-dot{
    border: calc(var(--fc-list-event-dot-width, 10px) / 2) solid var(--fc-event-border-color, #162d19);
}
.min-height {
    min-height: 800px;
}
ul.styled-list  > li {
    list-style: disclosure-open;
    margin-left: 15px;
}
ul.styled-list ul > li {
    list-style: disc;
    margin-left: 15px;
    font-size: 15px;
}
ul.styled-list .label {
    font-weight: bold;
}
.banner {
    @extend %card;
    margin-top: 12px;
    background: var(--contrast) !important;
    color: #fff;
    font-size: 22px;
    padding: 20px;
    line-height: normal;
}
.banner a {
    display: block;
    color: #fff !important;
    margin-top: 6px;
    font-size: 18px !important;
}

.two-column-form-check-container fieldset > div { 
    display: flex;
    flex-wrap: wrap;
}
.two-column-form-check-container fieldset > div div.form-check{ 
    flex: 0 0 50%;
    padding: 0 5px;
}
.two-column-form-check-container .form-check label::after {
    left: 9px;
    width: 10px;
    height: 10px;
}

img.contain {
    object-fit: contain !important;
}
.mb-2 {
    margin-bottom: .5rem !important;
}
.error {
    font-size: 0.8rem;
    color: rgb(250, 82, 35);
}
.video-link {
    display: block;

    .video-link-img {
        position: relative;
        display: block;
        width: 250px;
        height: 250px;

        img {
            width: 100%;
            height: 100%;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 100%;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 50px 0 50px 100px;
            border-color: transparent transparent transparent #fff;
        }
    }
}

.modal {
    display: none;
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 15px;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.88);
    -webkit-animation-duration: 0.35s;
    animation-duration: 0.35s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    /* Modifiers */
    /* States */
}
.modal__dialog {
    position: relative;
    max-width: 800px;
    min-width: 768px;
    padding: 20px;
    margin: auto;
    border-radius: 4px;
    background-color: #fff;
}
.modal__close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    border: none;
    color: #ccc;
    background-color: transparent;
    background-image: none;
}
.modal__close:focus {
    outline: 0;
}
.modal__header {
    border-bottom: 1px solid #e2e2e2;
}
.modal__title {
    margin: 0 0 15px;
}
.modal__content {
    padding: 10px 0;
    font-size: 13px;
    line-height: 1.6;
    color: #555;
}
.modal__footer {
    padding-top: 20px;
    border-top: 1px solid #e2e2e2;
    text-align: right;
}
.modal--fullscreen {
    padding: 5px;
}
.modal--fullscreen .modal__dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    border-radius: 0;
}
.modal.is-modal-active {
    display: flex;
}
/* Animation */
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// Modules
// ==================
@import 'modules/header';
@import 'modules/card';
@import 'modules/color';
@import 'modules/scrollbar';
@import 'modules/btn';
@import 'modules/form';
@import 'modules/alert';
@import 'modules/table';
@import 'modules/tabs';
@import 'modules/modal';
@import 'modules/loading-spinner';
@import 'modules/page';
@import 'modules/sidebar';
@import 'modules/nav';
@import 'modules/footer';
@import 'modules/avatars';
@import 'modules/pagination';
@import 'modules/chat';
@import 'modules/language-switcher';
@import 'modules/user-list';
@import 'modules/user-modal';
@import 'modules/rank-stars';
@import 'modules/custom-chart';
@import 'modules/calendar-schedule';
@import 'modules/meetings-table';
@import 'modules/tooltip';
@import 'modules/select';
@import 'modules/accordion';
@import 'modules/interactive-plan';

// Pages
// ==================
@import 'pages/registrations';
@import 'pages/auth';
@import 'pages/home';
@import 'pages/planning';
@import 'pages/meeting';
@import 'pages/user';
@import 'pages/error';

@import 'pages/planning_responsive';