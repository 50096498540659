.pagination {
    @extend %card;
    padding: space(2);
    margin-top: space(2);

    nav {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;

        & > span, & > a {
            width: 25px;

            &::after {
                content: '.';
            }

            &.current {
                font-weight: bold;
            }
        }

        & > span:first-child, & > a:first-child,
        & > span:last-child, & > a:last-child {
            color: white;
            display: flex;
            align-items: center;
            height: 100%;
            overflow: hidden;

            &::before, &::after {
                color: black;
                width: 35px;
            }
        }

        & > span.disabled:first-child, & > a:first-child {
            &::before {
                content: '<';
            }

            &::after {
                content: '';
            }
        }

        & > a:last-child::after, & > span.disabled:last-child::after {
            content: '>';
        }
    }
}