.tooltip {
    position: relative;
    width: fit-content;
}

// Popup
.tooltip .tooltip__content {
    @extend %card;
    padding: space(1);
    position: absolute;
    width: max-content;
    max-width: 50vw;
    bottom: 100%;
    left: 50%;
    margin-bottom: 5px;
}

// Indicator
.tooltip .tooltip__indicator {
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;

    &::before, &::after {
        position: absolute;
        top: -5px;
        left: 50%;
        transform: translateX(-50%);
    }

    &::before {
        content: "";
        height: 20px;
        border-color: var(--border) transparent transparent transparent;
        border-style: solid;
        border-width: 11px;
    }

    &::after {
        content: "";
        width: 0;
        height: 0;
        border-left: solid transparent 10px;
        border-right: solid transparent 10px;
        border-top: solid var(--card-bg, var(--background-light)) 10px;
    }
}

// Animations
.tooltip .tooltip__content,
.tooltip .tooltip__indicator {
    opacity: 0;
    transform: translateX(-50%) translateY(10px) scale(0.95);
    pointer-events: none;
    animation: none;
    z-index: -999;
    transition: all 0.3s ease-out;
}

.tooltip:hover .tooltip__content,
.tooltip:hover .tooltip__indicator {
    opacity: 1;
    transform: translateX(-50%) translateY(0) scale(1);
    pointer-events: auto;
    animation: auto;
    z-index: 999;
}