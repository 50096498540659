// Pages d'authentification
// ==================
.auth-container {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - var(--container-padding) * 2);
  max-width: 400px;
}
.auth-title {
//   color: #fff;
  text-align: center;
  margin-bottom: space(4);
}
.auth-form a {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
.auth-form {
  @extend %card;
  padding: space(3);
}
.auth-form > * + * {
  margin-top: space(3);
}
.auth-password-forgot {
  opacity: 0.4;
}
.auth-actions {
  font-size: 0.9rem;
}
