// Tabs bar
// =============
.tabs-bar {
  position: relative;
  display: flex;
  z-index: 2;
  &::after {
    content:'';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: var(--border-light);
  }
}
.tabs-bar small {
  font-size: 14px;
}
.tabs-bar a {
  display: block;
  padding: space(2);
  font-size: 1.2em;
  white-space: nowrap;
  position: relative;
  z-index: 2;
}
.tabs-bar a:hover {
  color: var(--contrast);
}
.tabs-bar .icon {
  width: 1rem;
  height: 1rem;
  margin-right: space(1);
}
.tabs-bar a[aria-selected='true'] {
  color: var(--contrast);
  border-bottom: 3px solid var(--contrast);
}
