// Keyframes
// ==================
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  to {
    opacity: 0;
  }
}
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes slideOut {
    from {
        opacity: 1;
        transform: translateY(0px);
    }
    to {
        opacity: 0;
        transform: translateY(5px);
    }
}

// Turbolink
// ==================
.turbolinks-progress-bar {
  height: 5px;
  background-color: var(--contrast);
}

// ScrollReveal
// ==================
.fade {
  opacity: 0 !important;
  transform: translateY(30px) !important;
}
.fade.in {
  opacity: 1 !important;
  transform: translateY(0px) !important;
  transition: 0.7s cubic-bezier(0.5, 0, 0, 1);
}
.fade-1 {
  transition-delay: 0.2s !important;
}
.fade-2 {
  transition-delay: 0.4s !important;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none !important;
  }
}
