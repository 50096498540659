// Base
// =============
.tomselected {
    display: none !important;
}

.ts-wrapper {
    position: relative;
}

.ts-control {
    display: flex !important;
    align-items: center;
    padding: 1px 8px !important;
    flex-wrap: wrap;
    @extend %input;
    overflow: hidden;
}

.ts-control.focus {
  @extend %input-active;
}

.ts-wrapper .ts-control input {
  border: none;
  padding: 0;
  background-color: transparent;
  width: auto;
  outline: none !important;
  box-shadow: none !important;
  display: inline-block;
  min-width: 7rem;
  max-width: 100%;
  min-height: 32px;
  flex: 1 1 auto;
}

.ts-wrapper .ts-control.full input {
  display: none;
}


// Dropdown
// =============
.ts-dropdown {
  white-space: nowrap;
  border: 1px solid var(--border);
  position: absolute;
  z-index: 100;
  min-width: 100%;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 8px;
  font-size: .875rem;
  background-color: var(--background-light);
  color: var(--color-light);
}

.ts-dropdown-content {
  overflow-x: hidden;
  max-height: 200px;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding: space(1);
}

.ts-dropdown .option,
.ts-dropdown .create,
.ts-dropdown .optgroup-header,
.ts-dropdown .no-results {
  padding: space(1);
  margin: space(0.5) 0;
  cursor: pointer;
  border-radius: 8px;
}

.ts-dropdown .option:hover,
.ts-dropdown .option.active {
  background: var(--background);
  color: var(--color);
}

.ts-dropdown .option .highlight {
  background-color: var(--contrast25);
  font-weight: bold;
  color: var(--color);
}

.ts-dropdown .optgroup-header {
  font-weight: bold;
  color: var(--color);
}

.ts-dropdown .optgroup-header ~ .option {
  padding-left: space(2);
}

.ts-dropdown .dropdown-input {
  @extend %input;
  border-left: none!important;
  border-right: none!important;
  border-top: none!important;
}

.no-search + .ts-wrapper .dropdown-input-wrap {
  height: 0;
  opacity: 0;
}

@media (min-width: 640px) {
  .ts-dropdown .option {
    padding-right: 100px;
  }
}

.ts-dropdown .spinner {
  position: absolute;
  top: 0;
  right: space(2);
  margin: space(1) 0 0 space(1);
  width: 16px;
  height: 16px;
  border-radius: 16px;
  border-left: solid 2px var(--contrast);
  display: block;
  animation: tsDropdownSpinner 1s linear infinite;
}

@keyframes tsDropdownSpinner {
  0% { transform: rotate(0deg)}
  100% { transform: rotate(360deg)}
}

// Single
// =============
.ts-wrapper.single .ts-control {
  padding-right: space(4)!important;
}
.ts-wrapper.single .ts-control::after {
  border-color: var(--color-light) transparent transparent;
  border-style: solid;
  border-width: 4px 4px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: -1px;
  position: absolute;
  right: space(1);
  top: 50%;
  width: 0;
  transition: border .3s;
}
.ts-wrapper.single .ts-control.focus::after {
  border-color: var(--color) transparent transparent;
}
.ts-wrapper.single .ts-control input {
  margin-left: .5rem;
}

// Multi
// =============
.ts-wrapper.multi .ts-control {
  padding: space(0.5) !important;
}

.ts-wrapper.multi .ts-control .item {
  white-space: nowrap;
  background: var(--contrast);
  color: white;
  font-size: 0.9rem;
  padding: 4px 10px;
  border-radius: 20px;
  margin-right: space(0.5);
  margin-bottom: space(0.5);
  width: fit-content;
}

.ts-wrapper .remove {
  padding: 0 space(1);
  margin-right: space(-1);
  opacity: .7;
  transition: opacity .3s;
  color: white;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }

  .single & {
    display: none;
  }
}
