.nav-dropdown span {
  color: var(--color-title-2);
  text-decoration: none;
  font-weight: 500;
  display: inline-block;
}

.nav-dropdown span::after {
  content: "\0025BE";
  padding-left: 10px;
}

// Dropdown Nav
// ============
.nav-dropdown {
    display: inline-block;
    position: relative;
}

.nav-dropdown span {
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
}

.nav-dropdown:hover ul {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
}

.nav-dropdown ul {
    opacity: 0;
    outline: 0;
    display: block;
    z-index: 100;
    position: absolute;
    pointer-events: none;
    top: calc(100% + 5px - 2 * var(--space));
    padding-top: calc(3 * var( --space));
    padding-bottom: calc(1 * var(--space));
    transition: opacity .3s,transform .3s;
    max-height: 80vh;
    transform: translateY(5px);
    // overflow: auto;
}

.nav-dropdown ul li a {
  text-decoration: none;
}
.header-nav li a {
  font-weight: 700;
  text-decoration: none;
  //color: var(--color-blue);
  color: var(--color-title-2);
}

.nav-dropdown ul .card {
    padding: space(2) space(3);
}

.icon.icon-home {
  color: var(--color-title-2);
}

// ================================================
// RESPONSIVE DESIGN 
// ================================================


// Tablettes 
// ===================================
@media screen and (max-width: 985px) {
    .is-open .nav-dropdown {
        min-height: 26px;
        height: auto;
        width: 100%!important;
    }

    .is-open .nav-dropdown ul {
        display: none;
        opacity: 1;
    }

    .is-open .nav-dropdown ul.active {
        display: block;
        // overflow: hidden;
    }

    .is-open .nav-dropdown ul {
        position: relative;
        top: 0;
        display: none;
    }
}

@media screen and (max-width: 968px) {

  .page-wrapper::before {
    background-image: url(../../images/header_ipad.jpg);
  }

}


// MOBILES 
// ===================================

@media screen and (max-width: 550px) {

  .header.is-open .header-side {
    top: 20px;
  }

  .header-nav .nav-dropdown, .header-nav .nav {
    display: none;
  }

  .nav-dropdown ul {
    opacity: 100;
  }

  .header.is-open .header-nav li{
    width: 90vw
  }

  .header-nav {
    display: flex;
  }

  .is-open .header-nav {
    overflow: scroll;
  }

  .header-nav li {
    padding: 5px 0;
    display: block;
    white-space: normal;
    height: unset;
  }

  .nav-dropdown ul {
    padding: 20px 0;
    display: block;
    white-space: normal;
    height: auto;
  }

  .header-nav .nav:first-child {
    margin-top: 50vh;
  }

  .header.is-open .header-nav {
    display: block;
    position: relative;
    overflow: hidden;
  }

  .header.is-open .header-nav .nav-dropdown {
    flex: 5;
    height: auto;
  }

  .nav-dropdown ul {
      position: relative;
      top: 0;
      display: none;
}   

  .nav-dropdown ul .card {
    padding: unset;
  }

  .card {
    border: 0 none;
  }

  .card li {
    display: unset;
  }

  .header.is-open .card li {
    display: unset !important;
  }

}