// Label
// ==================
label,
label + tool-tip {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 0.85em;
  margin-bottom: space(0.5);
}

legend {
  text-transform: uppercase;
  font-weight: bold;
  color: var(--color-title-2);
}

.text-decription.text-muted {
  font-size: 0.8rem;
  font-style: italic;
}

label.required::after {
    content: ' *';
    color: #fb4635;
}

input:disabled {
  opacity: 0.7;
}

// Honeypot
// =============
.form-optional {
  display: none!important;
}

// Champs texte
// ==================
.form-group input,
.form-group textarea,
.form-group select,
%input {
  border: 1px solid var(--border-input);
  background: var(--background-input);
  border-radius: 8px;
  color: var(--color);
  padding: space(1);
  display: block;
  width: 100%;
  outline: none;
  min-height: 42px;
}
.form-group textarea:not {
  min-height: 150px;
}
.form-group label {
  display: block;
}
.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%23121c4299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
  background-size: 12px 12px;
  background-position: center right 20px;
  background-repeat: no-repeat;

//   @include dark {
//     background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%238491c7' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
//   }
}

.search-input-group {
    position: relative;
    
    input[type="search"] {
        height: 45px;
    }
    
    label {
        position: absolute;
        top: 0;
        right: 0;
        background-color: var(--color-blue-light);
        width: 45px;
        height: 45px;
        margin: 0;
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;

        img {
            margin: auto !important;
        }
    }
}

// Checkbox
// ==================
.form-checkboxes {
  grid-column: 1 / -1;
}
.form-check {
  cursor: pointer;
  position: relative;
}
.form-check input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.form-check label {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.form-check label::before {
  content: '';
  display: inline-block;
  min-width: 18px;
  width: 18px;
  max-width: 18px;
  height: 18px;
//   margin-top: -3px;
    border-radius: 4px;
  border: 1px solid var(--border);
  background: var(--background);
  margin-right: space(1);
}
.form-check label::after {
  transition: transform 0.2s;
  content: '';
  position: absolute;
  //top: calc(50% - 9px);
  left: 4px;
  width: 10px;
  height: 10px;
  background: var(--contrast);
  border-radius: 2px;
  transform: scale(0);
}
.form-check input:checked + label::after {
  transform: scale(1);
}
.form-check input::after {
  content: '';
}

// Switch
// ==================
.form-switch {
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    min-height: auto;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    margin-bottom: 0 !important;
  }
}
.switch {
    position: relative;
  flex: none;
  width: 55px;
  height: 26px;
  display: inline-block;
  background: var(--background);
  border: 1px solid var(--border);
//   box-shadow: 0 0 8px var(--shadow);

  border-radius: 100px;
  margin-right: space(1);
  transition: background 0.3s;

  &::before {
      position: absolute;
    content: '';
    display: block;
    height: 18px;
    width: 18px;
    top: 50%;
    left: 4px;
    background: var(--background-light);
    border: 1px solid var(--border);
    // box-shadow: 0 0 8px var(--shadow);
    border-radius: 100px;
    transform: translateY(-50%);
    transition: transform 0.3s;
  }

//   @include dark {
//     box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);

//     &::before {
//       background: var(--color);
//       border-color: var(--color-light);
//       box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
//     }
//   }
}
input:checked + label .switch {
  background-color: var(--color-green);
}
input:checked + label .switch::before {
  transform: translateY(-50%) translateX(28px);
}

// Attachment
// ==================
.form-attachment {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

// Validation
// ==================
.form-error {
  color: #fb4635;
  font-size: 0.8em;
  margin-top: 0.5em;
  line-height: 1.2;

  * > * {
    margin-top: 0.5em;
  }
}
.form-group input.is-invalid,
.form-group textarea.is-invalid {
  border-color: #fb4635;
}
.invalid-feedback {
  color: #fb4635;
  margin-top: space(1);
  font-size: 0.9em;
}

// Accessibilité
// ==================
.form-group input:focus,
.form-check input:focus + label::before,
select:focus,
textarea:focus,
form button:focus,
input:focus + label .switch,
%input-active {
  border-color: var(--contrast);
  box-shadow: 0 0 0 3px var(--contrast25);
}

// Misc
// ==================
button:not([class]):hover .icon-delete {
  color: var(--red);
}

// Radio
// =============
.form-radio,
.form-checkbox {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--border);
  transition: border .3s;

  &::after {
    content:'';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: background-color .3s;
    pointer-events: none;
  }

  & + label {
    cursor: pointer;
  }

  &.is-checked {
    border-color: var(--contrast);
  }

  &.is-checked::after {
    background: var(--contrast);
  }

  input {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}

// Checkbox
// =============
.form-checkbox {
  border-radius: 3px;

  &::after {
    border-radius: 2px;
  }

  &.is-checked {
    border-color: var(--contrast);
  }

  &.is-checked::after {
    background: var(--contrast);
  }
}

#identity_registration_form_billingCountry,
#identity_registration_form_country, #select2-identity_registration_form_country-container, #select2-identity_registration_form_billingCountry-container {
  text-transform: uppercase;
}

#select2-identity_registration_form_country-results li {
  text-transform: uppercase;
}

#select2-identity_registration_form_billingCountry-results {
  text-transform: uppercase;
}

.conditional-logic {
    --max-height: auto;
    display: block;
    z-index: 1;
    opacity: 1;
    transition: 0.3s;
    max-height: var(--max-height);
    transition: z-index 0s ease 0s, max-height 0.5s ease 0s, opacity 0.5s ease 0.5s;
}

.conditional-logic[hidden] {
    display: block;
    z-index: -10;
    opacity: 0;
    max-height: 0;
    pointer-events: none;
    transition: opacity 0.5s ease 0s, max-height 0.5s ease 0.5s, z-index 0s ease 1s;
}