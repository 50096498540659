#chat-conversations-container {
    
    .chat-conversations {
        display: grid;
        grid-template-columns: 20% 80%;
        grid-template-rows: 1fr 50px;
        max-width: calc(100vw - 365px);
        max-height: 70vh;

        #conversations {
            grid-column: 1/2;
            grid-row: 1/3;

            max-height: 100%;
            overflow-y: scroll;

            border-right: 1px solid lightgray;
            padding: 10px;
            
            .conversation {
                padding: 10px;
                display: block;

                input[type="checkbox"] {
                    cursor: cell;
                }

                .picture {
                    display: flex;   
                    align-items: center;

                    img {
                        margin-right: 10px;
                    }
                }

                p {
                    margin: 0;
                    user-select: all;
                    // word-wrap: break-word;
                    // overflow: hidden;
                    // max-height: 3.6rem;
                    // line-height: 1.8rem;
                    // white-space: nowrap;
                    // text-overflow: ellipsis;
                }

                &:hover {
                    background-color: lightgray;
                }
                
                &:not(:last-child) {

                    border-bottom: 1px solid lightgray;
                }
            }
        }

        #messages {
            grid-column: 2/3;
            grid-row: 1/2;

            max-height: 100%;
            overflow-y: scroll;

            padding: 10px;
            
            .message {
                display: block;
                max-width: 45%;
                
                .rounded {
                    width: auto;
                    max-width: fit-content;
                    background-color: lightgray;
                    border-radius: 10px;
                    padding: 10px;

                    p {
                        width: auto;
                        font-size: 0.9rem;
                        margin: 0;
                    }
                }

                > p {
                    width: fit-content;
                    padding: 0;
                    font-size: 0.6rem;
                }

                &.mine {
                    margin-left: auto;

                    .rounded {
                        margin-left: auto;
                        background-color: lightblue;
                    }

                    p {
                        text-align: right;
                        margin-left: auto;
                    }
                }
            }
        }

        #input {
            grid-column: 2/3;
            grid-row: 2/3;

            form {
                display: grid;
                grid-template-columns: 1fr 50px;
                border-top: 1px solid lightgray;
                height: 100%;

                input[type="text"] {
                    height: 100%;
                }

                button[type="submit"] {
                    height: 100%;
                    border: 0;
                }
            }
        }
    }
}

#chat-box-container {
    display: none;
    position: fixed;
    bottom: 15%;
    right: -215px;
    z-index: 999;
    width: 250px;
    max-height: 50vh;
    border: 1px solid lightgray;
    border-radius: 5px 0 0 5px;
    background-color: white;
    min-height: 15vh;
    transition: 0.3s;
    &.open {
        right: 0;
    }

    h3 {
        padding: 10px;
        text-align: center;
        background-color: var(--green);
        color: white;
    }

    .toggle {
        float: left;
        font-size: 1rem;
        cursor: pointer;
    }
    
    .chat-box {
        height: calc(20vh + 35px);
        display: grid;
        grid-template-rows: 1fr 35px;

        #messages {
            grid-row: 1/2;
            height: 20vh;
            overflow-y: scroll;

            padding: 10px;
            
            .message {
                display: block;
                max-width: 45%;
                
                .rounded {
                    width: fit-content;
                    background-color: lightgray;
                    border-radius: 10px;
                    padding: 10px;

                    p {
                        width: fit-content;
                        font-size: 0.9rem;
                        margin: 0;
                        padding: 0;
                    }
                }

                > p {
                    width: fit-content;
                    padding: 0;
                    font-size: 0.6rem;
                }

                &.mine {
                    margin-left: auto;

                    .rounded {
                        margin-left: auto;
                        background-color: lightblue;
                    }

                    p {
                        text-align: right;
                        margin-left: auto;
                    }
                }
            }
        }

        #input {
            grid-row: 2/3;

            form {
                display: grid;
                grid-template-columns: 1fr 35px;
                border-top: 1px solid lightgray;
                height: 100%;

                input[type="text"] {
                    height: 100%;
                }

                button[type="submit"] {
                    height: 100%;
                    border: 0;
                }
            }
        }
    }
}