.meetings-table {

    .schedule__hour {
        width: 75px;
        border-right: 3px solid white;
    }
    
    .schedule__label {
        border-bottom: 3px solid white;
        
        small {
            margin-right: 10px;
        }
    }
    
    .schedule__actions {
        border-bottom: 3px solid white;
        width: 20%;
    }

    .meeting__hour {
        width: 125px;
        border-right: 3px solid white;
    }
    
    .meeting__label {
        border-bottom: 3px solid white;
        
        small {
            margin-right: 10px;
        }
    }
    
    .meeting__actions {
        border-bottom: 3px solid white;
        width: 250px;
    }

    td {
        padding: 5px;
    }
}