.bg {
  background: var(--background);
}

.bg-light {
  background: var(--background-light);
}

.bg-white {
    background: white !important;
}

.transparent {
  background-color: transparent !important;
}

