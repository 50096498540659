.homepage {

  /* positionnement contenu page en fonction du header [JMS 2021.09.14] */
  margin-top: 0;
  /* positionnement contenu page en fonction du header */
  --padding: 70px;
  margin-bottom: 400px;
}

// Introduction
// =============
.home-intro {
  padding: var(--padding) 0;
}

.home-intro__illustration {
  display: none;
}

@media (max-height: 700px) and (min-width: 1000px) {
  .home-intro {
    padding-top: calc(var(--padding) * .6);
  }
}

@include up(1000) {
  .homepage {
    --padding: 150px;
  }
  .home-intro {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 430px;
    grid-gap: 30px;
  }
  .home-intro__illustration {
    display: block;
    max-width: 654px;
    position: relative;
  }
  .home-intro__mascot {
    position: absolute;
    bottom: -120px;
    right: -100px;
    width: 472px;
    height: 460px;
  }
}

@include up(1330) {
  .home-intro__mascot {
    right: -280px;
  }
}

// CONTENT // [JMS 2021.09.14]

.home-intro__body{
//   background-color: white;
//   padding: 10px;
}

.home-sidebar {
}

.home_side_blocs {
//   border: 10px solid white;
//   border-bottom: 20px solid white;
  padding: space(1);
  background-color: #ecece9;
  border-radius: 8px;
  color: black;
}

.home_side_blocs h2 {
  display: block;
  border-bottom: 1px solid white;
  font-size: 1.6rem;
}

.home_side_blocs ul {
  list-style: disc;
}
.home_side_blocs li {
  margin-left: 40px;
  color: rgba($color: black, $alpha: 0.8);
}

.home_side_blocs a {
  color: var(--color-title-2);;
  text-decoration: none;
}

.border_top {
  display: block;
  margin-top: 10px;
  border-top: 1px solid white;
}