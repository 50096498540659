::-webkit-scrollbar {
    width: 10px;
    height: 7px;
}

body::-webkit-scrollbar-track {
    border-radius: 0;
    margin: 0;
}
::-webkit-scrollbar-track {
    background: var(--background-light);
    margin: 10px 0;
    border-radius: space(1);
    padding: 1px;
}

::-webkit-scrollbar-thumb {
    background: var(--border);
    border-radius: 4px;
}
