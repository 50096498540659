.sidebar {
    @extend %card;
    position: relative;
    //background: repeating-linear-gradient(-60deg, var(--color-blue-light-secondary), var(--color-blue-light-secondary) 15px, var(--color-blue-light) 15px, var(--color-blue-light) 35px);
    //background: linear-gradient(90deg, rgba(247,206,58,1) 0%, rgba(252,240,176,1) 46%, rgba(251,225,110,1) 100%);
    background: var( --background-input);
    padding: 0.5rem;
}

.sidebar .sidebar__container {
    position: sticky;
    top: 50px;
}

.sidebar .sidebar__section {
    display: block;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    
    &:hover,
    &[aria-selected=true] {
        background-color: var(--color-blue);
        a {
            color: white;}
    }
    
    a {
        color: var(--color-title-2);
        text-decoration: none;
        display: flex;
        align-items: center;
    }

    img {
        margin-right: 10px;
        max-width: 25px;
        max-height: 25px;
    }
    svg.icon {
        margin-left: 10px;
    }

    input, select {
        width: 100%;
    }

    label {
        color: var(--color-inversed);
    }

    .sidebar__section__number_big {
        color: var(--color-blue-light-secondary);
        margin: 10px 0;

        strong {
            font-size: 3.5rem;
            line-height: 3.5rem;
            font-weight: normal;
        }
    }
}