.btn,
%btn {
    text-decoration: none!important;
    padding: 9px 16px;
    border: none;
    display: inline-flex;
    align-items: center;
    box-shadow: 0 0 8px var(--shadow);
    border-radius: 8px;
    line-height: 1.6;
    font-family: $font-alt;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    transition: filter 0.3s, background 0.3s, color 0.3s;
    font-size: 0.9em;
    filter: brightness(1);
    white-space: nowrap;

    &[disabled] {
        cursor: default;
        filter: grayscale(1);
        opacity: 0.7;
    }

    &:hover[disabled] {
        filter: grayscale(1);
    }

    &:hover {
        filter: brightness(1.2);
    }

    .icon {
        width: 16px;
        height: 16px;
    }
    
    &.left-icon .icon {
        margin-right: space(1);
    }
}

.btn-primary {
  @extend %btn;
  background: var(--contrast);
}

.btn-primary-outlined {
  @extend %btn;
  border: 1px solid var(--contrast);
  color: var(--contrast);
  box-shadow: none;

  &:hover {
    filter: none;
    background: var(--contrast);
    color: #fff;
  }
}

.btn-white-outlined {
  @extend %btn;
  border: 1px solid currentColor;
  box-shadow: none;

  &:hover {
    filter: none;
    background: rgba(255, 255, 255, 0.1);
  }
}

.btn-danger {
  @extend %btn;
  background: var(--red);
}

.btn-secondary {
  @extend %btn;
  font-weight: normal;
  color: var(--color) !important;
  background: #ffff;

  &:focus,
  &.active,
  &[aria-expanded="true"] {
    color: var(--contrast);
    border-color: var(--contrast) !important;
  }

//   @include dark {
//     border-color: #323970 !important;
//     background: #323970 !important;

//     &:focus,
//     &.active,
//     &[aria-expanded="true"] {
//       border-color: var(--contrast) !important;
//     }
//   }
}

.btn-big {
  padding: 12px 16px;
  font-size: 1rem;
  font-weight: 700;
  white-space: initial;

  @include up(1100) {
    font-size: 18px;
  }

  .icon {
    margin-right: space(2);
  }
}

.btn-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-small {
  padding: 5px 10px;
  font-size: 0.8em;
}

.btn-group {
  display: inline-flex;

  & > .active {
    z-index: 2;
    position: relative;
  }

  & > * {
    z-index: 1;
    border-radius: 0 8px 8px 0;
    margin-left: -1px;
  }

  & > *:first-child {
    border-radius: 8px 0 0 8px;
  }
}

.stack > button {
  justify-self: flex-start;
}

// Bouton Steps
// ============
.steps-btns {
    display: flex;
    justify-content: space-between;
    justify-content: center;
    gap: 2px;

    .next-step {
        margin-left: auto;
    }
}

// Bouton rond
// =============
.rounded-button {
  box-sizing: content-box;
  width: 37px;
  height: 37px;
  border-radius: 37px;
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  z-index: 0;

  &::after {
    content:'';
    position: absolute;
    top: calc(50% - 9px);
    left: calc(50% - 9px);
    width: 18px;
    height: 18px;
    z-index: -1;
    border-radius: 50%;
    background: var(--icon-background);
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--icon-background);
    content:'';
    border-radius: 37px;
    z-index: -1;
    transition: transform .3s;
    opacity: .2;
  }

  .icon {
    width: 14px;
    height: 14px;
  }

  &:hover::before {
    transform: scale(1.2);
  }

  &:disabled {
    opacity: .4;
  }
}

.rounded-button.warning {
  &::after {
    background: url(/assets/images/hexagon.svg) center center / 18px 18px;
    border-radius: 0;
  }
  &::before {
    background: var(--border-light);
    opacity: 1;
    // @include dark {
    //   background: #FFFFFF19;
    // }
  }
}

.rounded-button.danger {
  --icon-background: var(--red);
}

.rounded-button.success {
  --icon-background: var(--green);
}

// Couleurs
// =============
.btn-social {
  @extend %btn;
  text-align: center;
  display: block;
  padding: 12px 16px;
  border-radius: 8px;
  position: relative;
  margin: 0 5px;

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: space(2);
  }
}