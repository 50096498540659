.user-card {
    // background-color: var(--color-blue-light-secondary);

    .card__body {
            
        p {
            margin: 0;
            padding: 0;
        }

        .user-card__infos {
            font-size: 0.8rem;
        }
    }
    
    .card__footer {
        background-color: var(--color-blue);
        color: var(--color-inversed);
        grid-template-columns: repeat(2, 1fr);

        .user-card__addMeting {
            margin-top: -35px;
            width: 35px;
            height: 35px;
            border-width: 1px;
        }

        user-modal {
            color: white;
        }
    }
}