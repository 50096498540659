form {
    position: relative;

    .th_error-required {
        border-color: var(--red)!important;
        box-shadow: 0 0 10px var(--red)!important;
    }
    
    .checkbox-th_error-required::before {
        border-color: var(--red)!important;
        box-shadow: 0 0 10px var(--red)!important;
    }

}

.modal-th_error-required {
    position: fixed;
    top: 50%;
    right: 50px;
    width: calc(50vw - 100px - var(--container-width) / 2);
    min-width: 400px;
    padding: 20px;
    padding-left: 30px;
    border-radius: 10px;
    background-color: white;
    transform: translateY(-50%);
    overflow: hidden;

    .close_btn {
        position: absolute;
        top: 10px;
        right: 10px;
        line-height: 1;
        cursor: pointer;
    }

    &::before {
        content: "";
        position: absolute;
        width: 10px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: var(--red);
    }
    
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #f2535324;
        z-index: -1;
    }

    ul {

        label {
            cursor: pointer;
            font-weight: bold;
            text-transform: initial;
            font-size: 1rem;
        }
    }
}

.company-logo { margin: 10px 0}

#select2-activity_registration_form_prioritySoughtMarkets-results,
#select2-activity_registration_form_secondarySoughtMarkets-results,
#select2-activity_registration_form_secondarySoughtMarkets-container,
#select2-activity_registration_form_prioritySoughtMarkets-container {
    text-transform: uppercase;
}

.field-lenght-info {
    float: right;
    font-size: 12px;
}

.ag-activity-form {
    .col-form-label {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 0.85em;
        margin-bottom: calc(0.5 * var(--space));
        color: var(--color);
    }
}