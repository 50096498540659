/* Positionnement z-index des contenus du corps de page [JMS 2021.09.14] */
main {
  z-index: 1;
}

.page__title {
  margin-bottom: space(7);
}

// Page header
// ==========================================================================
.page-header {
  background-color: rgba($color: white, $alpha: 0.8);
  margin-top: space(3);
  padding-bottom: space(6);
  padding-top: space(6);
  border-bottom: 1px solid var(--border);
}

// CONTENU DES PAGES 
// ==========================================================================
.page_content {
  min-height: 550px;
}

// FORMULAIRE DE CONTACT
// ==========================================================================
.contact .bg {
  background: transparent;
}


// Page alternant des blocks de couleurs
// ==========================================================================
.sections > * {
  @extend %container;
  padding-top: 115px;
  padding-bottom: 115px;
}


// Page Programme
// ==========================================================================

.h2_programme {
  display: block;
  border-bottom: 1px solid var(--color-blue);
} 

.program_main, .program_main_impair {
  display: flex;
  flex-direction: row;
  font-size: 1.2rem;
  line-height: 1.6rem;
}
.program_main p, .program_main_impair p {
  padding: 0;
}

.program_left_column {
  flex: 1;
  text-align: right;
  margin-top: 5px;
  padding: 5px 10px;
  font-weight: 700;
}

.program_right_column {
  flex: 5;
  margin-top: 5px;
  padding: 5px 10px;
}

// Page INFORMATIONS PRATIQUES
// ==========================================================================

.informations-pratiques p {
  font-size: 1.2rem;
}
.infprat_main {
  display: flex;
  flex-direction: row;
  line-height: 1.6rem;
}
.infprat_main p {
  padding: 0;
}

.infprat_main_left_column {
  flex: 1;
  text-align: center;
  margin-top: 5px;
  padding: 5px 10px;
  font-weight: 700;
}

.infprat_main_right_column {
  flex: 6;
  margin-top: 5px;
  padding: 5px 10px;
}

// Page CHIFFRES
// ==========================================================================

.figures_main, .figures_main_impair {
  display: flex;
  flex-direction: row;
  font-size: 1.2rem;
  line-height: 1.6rem;
}
.figures_main p, .figures_main_impair p {
  padding: 0;
}

.figures_left_column {
  flex: 1;
  text-align: left;
  margin-top: 5px;
  padding: 5px 10px;
}

.figures_main .figures_left_column {
  background-color: var(--color-td-light);
}
.figures_main_impair .figures_left_column {
  background-color: var(--color-td-grey-light);
}

.figures_right_column {
  flex: 1;
  margin-top: 5px;
  padding: 5px 10px;
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
}

.figures_main .figures_right_column {
  background-color: var(--color-td);
}
.figures_main_impair .figures_right_column {
  background-color: var(--color-td-grey);
}

// Tableaux Simples
// ==========================================================================

.simple_table {
  display: flex;
  flex-direction: row;
}

.simple_table .left-column {
  flex: 1 50%;
  margin: 5px;
  padding: 10px;
  background-color: rgba($color: #fff, $alpha: 0.7);
}

.simple_table .right-column {
  flex: 1 50%;
  margin: 5px;
  padding: 10px;
  background-color: rgba($color: #fff, $alpha: 0.7);
}

.column-align-center {
  text-align: center;
}

.column-align-left {
  text-align: left;
}

.bkgrnd_blue {
  background-color: var(--color-td);
}

.bkgrnd_blue_light {
  background-color: var(--color-td-light);
}

.bkgrnd_grey {
  background-color: var(--color-td-grey);
}

.bkgrnd_grey_light {
  background-color: var(--color-td-grey-light);
}

// Tableaux TROIS COLONES
// ==========================================================================

.table-tree-col {
  display: flex;
  flex-direction: row;
  margin: 5px;
}

.table-tree-col img {
  max-height: 150px;
}

.table-tree-col .table-tree-col-left {
  flex: 2 50%;
  padding: 10px;
  border-bottom: 1px solid var(--color-blue);
}

.table-tree-col .table-tree-col-others {
  flex: 1 25%;
  padding: 10px;
  border-left: 3px dotted var(--color-blue);
  border-bottom: 1px solid var(--color-blue);
  text-align: center;
}

.table-tree-col .table-tree-col-others-33 {
  flex: 1 31.8%;
  margin: 0 0.2%;
  padding: 10px;
  text-align: center;
  border: 1px solid var(--border);
  background-color: rgba($color: #ffffff, $alpha: 1);
}

.table-tree-col .table-tree-col-others-free {
  margin: 0 0.2%;
  padding: 10px;
  text-align: center;
  background-color: rgba($color: #ffffff, $alpha: 0.8);
}

.td-dark {
  background-color: var(--color-dark);
  color: white;
}

.td-pair {
  background-color: var(--color-td-light);
}
.td-impair {
  background-color: var(--color-td-grey);
}

// Ligne Twitter
// ==========================================================================

.twitter_home {
  margin-top: 2rem;
}

form[name="identity_registration_form"] .text-muted,
form[name="activity_registration_form"] .text-muted {
  font-size: 0.8rem;
}

form[name="identity_registration_form"] ul {
  padding-left: 30px;
}

form[name="identity_registration_form"] li {
  list-style: disc;
}

// edit activity
// ================================================

legend.required::after {
  color: #fb4635;
  content: " *";
}

// edit_services
// ==========================================================================

form[name="services_registration_form"] .name {
  width: 40%;
}

form[name="services_registration_form"] .price, form[name="services_registration_form"] .quantity, form[name="services_registration_form"] .amount, form[name="services_registration_form"] .ttc-amount {
  width: 15%;
}

form[name="services_registration_form"] .price, form[name="services_registration_form"] .amount, form[name="services_registration_form"] .ttc-amount {
  text-align: right;
  padding-right: 5px !important;
}

form[name="services_registration_form"] .quantity {
  padding-left: 10px;
  padding-right: 10px;
}

form[name="services_registration_form"] #general-total {
  background-color: var(--contrast);
  color: white;
}

@media screen and (max-width: 700px) {
    .program_main, .program_main_impair {
        flex-direction: column;
    }

    .infprat_main {
        flex-direction: column;
      }
}