.chart-container {
    position: relative;
    margin: auto;
    text-align: center;
    
    > .chart__value {
        color: var(--color-blue);
    }
}

custom-chart {
    
    .chart__value {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-top: 10px;
        font-size: 1rem;
        color: var(--color-blue);
    }
}

.chart__total {
    font-size: 1.5rem;
    color: var(--color-blue);
}