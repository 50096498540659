.modal-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;

    opacity: 1;
    transition: 0.3s;
    animation: modalIn .3s;
}

.modal-dialog.out {
    animation: modalOut .3s !important;
}

.modal-dialog[hidden],
.modal-dialog.out {
    opacity: 0;
    transform: scale(1.05);
    pointer-events: none;
}

.modal-box {
    position: relative;
    width: 75vw;
    // max-width: 580px;
    max-height: calc( 100vh - 70px );
    background-color: var(--background-light);
    border: solid 1px var(--border);
    padding: space(2);
    border-radius: 16px;
    overflow: auto;
}

.modal-box header {
    text-align: center;
    margin-bottom: space(3);
}

.modal-box header img {
    max-width: 100px;
    margin-top: -100px;
}

.modal-close {
    z-index: 1;
    width: 15px;
    height: 15px;
    background-color: transparent;
    position: absolute;
    top: space(2);
    right: space(2);
    border: none;
    cursor: pointer;

    .icon {
        width: 100%;
        height: 100%;
    }

    &:hover {
        color: var(--contrast);
    }
}

@keyframes modalIn {
    0% {
        opacity: 0;
        transform: scale(1.05);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes modalOut {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(1.05);
    }
}