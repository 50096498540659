.loading-spinner {
    --size: 18px;

    display: flex;
    width: fit-content;
    gap: 3px;

    > span {
        background: var(--color);
        border-radius: 2px;
        width: calc(var(--size) / 3);
        height: calc(var(--size) / 1);
        animation: stretch 1s ease-in-out infinite;
    }
    > span:nth-of-type(2) {
        animation-delay: .15s;
    }
    > span:nth-of-type(3) {
        animation-delay: .3s;
    }
}

@keyframes stretch {
	60% {
        transform: scaleY(1);
    }
	30% {
        transform: scaleY(1.6);
    }
}