.header {
    @extend %container;
    position: relative;
    position: sticky;
    top: 0;
    margin-top: 550px;
    margin-top: 300px;
    max-height: 45px;
    font-family: $font-alt;
    font-weight: 500;
    line-height: 1;
    padding-top: space(0.5);
    padding-bottom: space(0.5);
    box-shadow: 0 0 12px var(--shadow);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    --border-header: var(--border);;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        //background-color: var(--border-header);
        max-width: var(--container-width);
        margin-left: auto;
        margin-right: auto;
        height: 1px;
    }
}

.header-light .header {
  background-color: var(--background-light);
}

.header.is-inversed:not(.is-open) {
  color: var(--color-inversed60);
  --border-header: var(--color-inversed60);

  .text-muted {
    color: inherit;
    opacity: .6;
  }

  a:hover,
  a[aria-current],
  button:hover {
    color: var(--color-inversed);
    opacity: 1;
  }

  &::after {
    opacity: 0.3;
  }

}
.header svg {
  width: 12px;
  height: 12px;
  margin-right: space(1);
}
.header__home svg {
  width: 14px;
}
.header__home svg.icon-logo {
  width: 24px;
  height: 24px;
}
.header ul {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}
.header-nav {
  height: auto;
  max-height: 45px;
  margin-top: -5px;
  margin-bottom: -5px;
  flex-wrap: wrap;
  overflow: visible;
}
.header-nav small {
  font-weight: normal;
}
.header-nav li {
  display: flex;
  align-items: center;
  height: 26px;
  white-space: nowrap;
}
.header-nav li[hidden] {
  display: none;
}
.header-nav .icon-star {
  width: 14px;
  height: 14px;
  margin-bottom: -1px;
}
.header a:hover,
.header button:hover,
.header a[aria-current] {
  color: var(--contrast);
}
.header-nav > * + * {
  margin-left: space(6);
}
.header-side {
  margin-right: space(-2);
  margin-left: space(2);
}
.header-side > li {
  margin-right: space(2);
}
.header-side > li > a {
    display: flex;
    place-items: center;
}
.header-side__auth {
  white-space: nowrap;
}
.header .header__home {
  display: flex;
  align-items: center;
  height: 16px;
  justify-content: flex-start;
  border-right: 1px solid var(--border-header);
  margin-right: space(2);
  padding-right: space(1);
  margin-top: 5px;
  margin-bottom: 5px;

  // On rend le lien plus grand que le container pour le rendre plus facilement clickable
  a {

    display: flex;
    align-items: center;
  }
}
.header__home + li {
  margin-left: 0;
}
.header__account a {
  display: flex;
}
.header__account span {
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header__account svg {
  display: block;
  align-self: center;
}
.header__logout {
  span {
    display: none;
  }
  svg {
    margin-right: 0;
  }
}
.header__burger {
  display: none;
  margin-left: space(1);
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
  }
  span::after, span::before, span {
    content:'';
    display: block;
    width:12px;
    height: 2px;
    background: currentColor;
    position: relative;
    transition: .3s;
    transition-property: top, bottom, transform;
  }
  span::after {
    top:-6px;
  }
  span::before {
    bottom:-4px;
  }
  span {
    font-size: 0;
    text-indent: -3000px;
  }
}

// Déclinaison fixed
// =============
@include down(985) {
  .header-nav > * + * {
    margin-left: space(4);
  }
  .header-side__auth {
    display: none;
  }
  .header__burger {
    display: block;
  }
  .header .header-nav li:last-child {
    display: none;
  }
  .header .header-nav .header__home {
    //display: block;
    border: none !important;
  }
  .header.is-open {
    color: var(--color);
    display: block;
    padding: 20px;
    position: relative;
    height: calc(100vh - 550px);
    overflow-y: scroll;
    margin: 0;
    max-height: inherit;
  }
  .header.is-open a{
    opacity: 1;
    color: var(--color) !important;
  }
  .header.is-open a:hover,
  .header.is-open a[aria-current] {
    opacity: 1;
    color: var(--contrast) !important;
  }
  .header.is-open .header-nav {
    display: block;
    justify-items: center;
    align-content: center;
    width: 100%;
    background: rgba(255, 255, 255);
    max-height: unset;
    padding-bottom: 20px;

    // @include dark {
    //   background: #171933D8;
    // }

    & li {
      display: block!important;
      width: 200px;
      margin-left: 0 !important;
      animation: slideIn .5s both;
      font-size: 1.3rem;
      animation-delay: .3s
    }

    .header__home {
      display: none!important;
    }

    @for $i from 1 through 6 {
      & li:nth-child(#{$i}) {
        animation-delay: .05s * $i;
      }
    }
  }
  .header.is-open .header-side__auth {
    display: block;
    white-space: nowrap;
  }
  .header.is-open .header-side {
    position: absolute;
    top: space(3);
    right: space(2);
    z-index: 4;
  }
  .header.is-open .header__account span {
    display: block;
  }
  .header.is-open .header__burger {
    span::after {
      top: -2px;
      transform: rotate(0deg);
    }
    span {
      transform: rotate(-135deg);
    }
    span::before {
      bottom: 0;
      transform: rotate(-90deg);
    }
  }
}

@include down(600) {
  //.header__account span, .header-side {
  //  display: none !important;
  //}
}



// ================================================
// RESPONSIVE DESIGN 
// ================================================


// Tablettes 
// ===================================
@media screen and (max-width: 968px) {

  .page-wrapper::before {
    background-image: url(../../images/header_ipad.jpg);
  }

}


// MOBILES 
// ===================================

@media screen and (max-width: 628px) {

  .page-wrapper::before {
    background-image: url(../../images/header_mobile.jpg);
    background-size: contain;
  }

  .header {
    margin-top: 250px;
    margin-top: 32.5vw;
    padding-top: 5px;
    max-height: inherit;
  }

  .header.is-open {
    height: calc(100vh - 250px);
    margin: 0;
  }

}