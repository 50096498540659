interactive-plan {
    width: 100%;
    overflow: hidden;
    position: relative;
}

interactive-plan .search input[type="search"][name="search_items"] {
    @extend %input;
    background-color: white;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;

    /* clears the ‘X’ from Internet Explorer */
    &::-ms-clear { display: none; width : 0; height: 0; }
    &::-ms-reveal { display: none; width : 0; height: 0; }
    /* clears the ‘X’ from Chrome */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }
}

interactive-plan .search {
    position: relative;
}

interactive-plan .search #empty-search {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    background: #e0e0e0;
    display: flex;
    justify-content: center;
    place-items: center;
    cursor: pointer;

    &:hover {
        background: lightgray;
    }
}

interactive-plan .search .list {
    @extend %card;
    display: block;
    width: 100%;
    max-height: 250px;
    overflow: auto;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 9;

    &[hidden] {
        opacity: 0;
        pointer-events: none;
        transform: translateY(5px);
        z-index: -1;
    }
}

interactive-plan .search .list li {
    background-color: white;
    margin: 0 !important;
    padding: 5px 10px;
    list-style-type: none;
    transition: color 0.3s;
    cursor: pointer;
}
interactive-plan .search .list li:hover {
    background-color: #ededed;
}
interactive-plan .search .list li.is-active {
    color: var(--contrast) !important;
    text-decoration: underline;
    font-weight: bold;
}

interactive-plan .plan {
    position: relative;
}

interactive-plan .plan .popup {
    @extend %card;
    display: block;
    position: absolute;
    outline: none;
    overflow: visible;
    transition: opacity .3s, transform .3s;
    z-index: 100;

    &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: var(--card-bg, var(--background-light)) transparent transparent transparent;
    }
}
  
interactive-plan .plan .popup[hidden] {
    opacity: 0;
    pointer-events: none;
    transform: translateY(5px);
}

interactive-plan .plan .popup .popup__content {
    display: flex;
    justify-content: center;
    place-items: center;
    width: 150px;
    height: 100px;
    padding: space(1);
    overflow: auto;
}

interactive-plan .plan .popup .popup__content img {
    max-width: calc(150px - 16px);
    max-height: calc(100px - 16px);
    display: block;
    margin: 0 auto;
}

interactive-plan .plan .popup .popup__content p {
    font-size: 0.7rem;
    margin: 0;
    padding: 0;
}

interactive-plan .image {
    position: relative;
    width: 100%;
    overflow: auto;
}

interactive-plan .image svg {
    min-width: 1200px !important;
    min-height: 800px !important;
}

interactive-plan .image .is-active {
    position: relative;
}

interactive-plan .image .is-active > * {
    fill: darkmagenta !important;
}