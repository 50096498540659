.profil-header {
  text-align: center;
}

// Avatar
// =============
.user-card__avatar {
  position: relative;
}
.user-card__avatar .vineyard-label {
  position: absolute;
  width: 50px;
  left: 0;
  z-index: 1;
}
.user-card__infos__societyName,
.user-card__infos__country {
  font-weight: 400;
  text-transform: uppercase;
  color: #191919;
}
.profil-header__avatar {
  display: block;
  width: 110px;
  height: 110px;
  border-radius: 110px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-color: var(--border);
  border: solid 5px white;
}
.profil-header__avatar img {
  width: 100%;
  height: 100%;
  border-radius: 110px;
  transition: opacity 0.3s;
  object-fit: cover;
}
.profil-header__avatar input {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.profil-header__body p {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-light);
}
.profil-header__upload {
  width: 110px;
  height: 25px;
  background: var(--border);
  transform: translateY(100%);
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #9fb3c8;

  .icon {
    width: 18px;
    height: 15px;
  }
}
.profil-header__avatar:hover .profil-header__upload {
  transform: translateY(0);
}
form.profil-header__avatar:hover img {
  opacity: 0.7;
}

@include up(600) {
  .profil-header {
    text-align: left;
    display: grid;
    grid-template-columns: 110px 1fr;
    align-items: center;
    grid-gap: space(2);
  }
  .profil-header__avatar {
    margin-left: 0;
  }
  .profil-header__body p {
    justify-content: flex-start;
  }
}
