@media only screen and (max-width: 600px) {
    .btn-logout {
        margin: 3px 10px 0 10px;
    }
    .btn-logout span{
        display: none;
    }
    .calendarContainer {
        height: 1400px;
    }
    .fc-scroller {
        height: auto !important;
        overflow-y: hidden !important;
    }
    .fc-timegrid-slots table tr td:first-of-type {
        display: none;
    }
    .fc-timegrid-event-harness {
        position: initial;
    }
    a.fc-timegrid-event > span:first-of-type {
        display: block;
    }
    a.fc-timegrid-event .rightButton > *{
        margin: 0 4px;
    }
    #planningCalendar #calendar .fc-event:not(.lunch) {
        display: block !important;
    }
    #planningCalendar #calendar .reserved .planningFullName {
        margin-left: 0;
    }
    #planningCalendar #calendar .lunch {
        height: 100px;
    }
    
}
@media only screen and (max-width: 380px) {
    .btn-logout {
        margin: 3px 2px 0 4px;
    }
    .btn-profile span {
        display: none;
    }
}