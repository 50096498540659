$profil-green-dark: #6CAF26;
$profil-green-light: #89BF51;
$profil-secondary-green-dark: #59911F;
$profil-secondary-green-light: #BDDAA3;
$profil-blue-dark: #004F84;
$profil-blue-light: #CCDCE6;
$profil-gray-blue-dark: #252D3F;
$profil-gray-blue-light: #3c4a65;
$profil-white: #eeeeee;
$profil-gray-dark: #ACAFB7;
$profil-gray-light: #EAECEE;

$profil-available: #F0F7E9;
$profil-empty: #F6E5CC;
.clearfix {
  clear: both;
  overflow: auto;
  zoom: 1;
}
@include down(985) {
    .other-user-planning .planningPage .planning-sidebar {
        display: none !important;
    }
}

.planningPage {
  a {
    color: inherit;
    font-size: inherit;
    text-decoration: inherit;
  }

  p {
    padding: 0;
  }

  ul, li {
    list-style: none;
    padding: 0;

    ul {
      padding-left: 15px;
    }
  }

}

.planning-intro__body {
  background-color: white;
  padding: 10px;
}

.planning-intro {
  position: relative;
}

.planning-mainHeader {
  height: 195px;
  position: relative;
  background: $profil-gray-blue-light;
  color: white;

  footer {
    height: 25px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: $profil-gray-blue-dark;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      justify-content: space-between;
    }

    .language-switcher {
      display: flex;
      align-items: center;
      margin-left: 10px;

      li {
        margin-left: initial;
        padding: 2px 5px;
      }
    }
  }

  .planning-userProfil {
    padding: 0 10px 5px 15px;
    display: flex;

    .planning-userPicture {
      margin-right: 50px;
    }

    img {
      position: relative;
      z-index: 1;
      height: 150px;
      max-height: 150px;
      // height: 165px;
      border-radius: 50%;
      border: 5px solid $profil-white;
      margin-top: 3px;
      width: 150px;
      max-width: 150px;
      object-fit: cover;
    }

    .profil-name {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        padding: 0;
      }

      .welcome {
        color: $profil-gray-dark;
      }
    }
  }
}

.planning-sidebar {
  position: fixed;
  z-index: 5;
  top: 195px;
  left: -84%;
  width: 86%;
  height: calc(100vh - 195px);
  background: repeating-linear-gradient(-60deg, $profil-green-dark,
      $profil-green-dark 15px, #70B22C 15px,
      #70B22C 35px);

  & > div {
    position: relative;

    & > ul {
      overflow: auto;
      background-color: $profil-green-dark;
    }
  }

  .planning-sidebar-displayBar {
    position: absolute;
    top: 0;
    right: -15px;
    width: 25px;
    height: calc(100vh - 195px);
    background: $profil-green-dark;
    color: $profil-white;

    &:before {
      content: '>>';
    }
  }

  &.open {
    left: 20px;

    .planning-sidebar-displayBar:before {
      content: '<<';
    }
  }

  ul li {
    padding: 0 15px;
    margin: 0;
    color: $profil-white;

    &:first-child {
      padding-top: 15px;
    }

    a {
      display: flex;
      width: 100%;
      height: 60px;
      padding: 5px 0;
      align-items: center;
    }

    & > a {
      border-bottom: 1px solid $profil-white;
    }

    ul {
      li {
        height: 25px;

        &:first-child {
          padding-top: 0;
        }
      }

      a {
        display: block;
        padding: 0;
      }
    }

    label[for="planningSearch"] {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: $profil-gray-blue-light;

      img {
        margin: 0;
        width: 80%;
      }
    }

    .navContent {
      display: none;
      padding-bottom: 15px;

      p:first-child {
        color: $profil-secondary-green-light;
        margin-bottom: 10px;

        span {
          font-size: 3.5rem;
          line-height: 3.5rem;
          font-weight: normal;
        }
      }

      .planning-searchInput {
        display: flex;

        label {
          background: $profil-blue-dark;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
        }
      }

      li.active a {
        text-decoration: underline;
        font-weight: bold;
      }
    }

    &.open {
      background-color: $profil-secondary-green-dark;

      .navContent {
        display: block;
      }

      a {
        border-bottom-width: 0;
      }
    }

    &.active {
      background-color: $profil-green-light;
      font-weight: bold;
    }

    img {
      margin-right: 10px;
    }

    input, select {
      width: 100%;
      height: 30px;
      padding: 5px 10px;
    }
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: $profil-blue-dark;
  }

  hr {
    margin: 10px 0;
  }

  img,svg {
    max-width: 30px;
    color: white;
  }

  .reg-steps li {
    a {
      font-weight: normal;
    }

    &.active a {
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

.panning-chart {
  display: grid;
  grid-template-columns: 420px 220px 276px;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  height: 452px;
  color: #59911F;

  h2 {
    font-size: 0.7rem;
    line-height: 1.2rem;

    span {
      font-size: 1.1rem;
      display: block;
      color: #0d9488;
      line-height: 1.1rem;
    }
  }

  .valueOfChart {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 70px;
    z-index: 999999999999999;
    background: transparent;
  }

  .planning-div1, .planning-div2, .planning-div3, .planning-div4 {
    padding: 10px;
    height: 220px;

    .chartContainer {
      position: relative;
      margin: auto;
    }
  }

  .planning-div1 {
    grid-area: 1 / 1 / 3 / 2;
    height: 100%;

    .chartContainer {
      width: 300px;
      height: 300px;
    }

    h2 span {
      font-size: 1.4rem;
    }
  }

  .planning-div2 {
    grid-area: 1 / 2 / 2 / 3;

    .chartContainer {
      width: 120px;
      height: 120px;
    }

    .valueOfChart {
      font-size: 45px;
      padding-top: 10px;
    }

    & > div {
      text-align: right;
      font-size: 1.5rem;
    }
  }

  .containerCounter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    justify-content: space-between;
  }

  .planningDivDate {
    border-bottom: 1px solid #6BAF25;
    padding: 0;
    font-size: 15px;
  }

  .planning-div3 {
    grid-area: 1 / 3 / 2 / 4;

    .chartContainer {
      width: 100px;
      height: 100px;
    }

    h2 span {
      font-size: 0.9rem;
    }

    .valueOfChart {
      font-size: 23px;
      padding-top: 10px;
    }

    .containerCounter {
      height: calc(100% - 56px);

      & > div > div {
        text-align: right;
        font-size: 1.5rem;
      }
    }

    .planningDivDate {
      font-size: 10px;
    }
  }

  .planning-div4 {
    grid-area: 2 / 2 / 3 / 4;
    padding: 0;

    p {
      padding: 0;
    }

    .chartContainer {
      width: 100px;
      height: 100px;
    }

    h2 {
      padding: 10px;
      height: 56px;
    }

    .containerCounter {
      height: calc(100% - 56px);
    }

    .planningDiv4Left, .planningDiv4Right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p:nth-child(2n) {
        text-align: right;
        padding: 0;

        span {
          text-align: center;
          display: block;
          color: #414877;
          font-size: 4.5rem;
          line-height: 4.5rem;
        }
      }

      footer {
        background: #6BAF25;
        height: 25px;
        text-align: center;
        padding: 2px 0;
        color: white;
      }
    }

    .planningDiv4Left .planningDivDate {
      margin-left: 10px;
    }

    .planningDiv4Right .planningDivDate {
      margin-right: 10px;
    }
  }

  & > div {
    background: #C9E2B0;
  }

  canvas {
    width: 100%;
    height: 100%;
  }
}

#listVisitor {
  p {
    margin: 0;
    padding: 0;
  }

  .listVisitor-card {
    background: #CCDCE6;
    margin-bottom: 5px;
    position: relative;

    img {
        object-fit: cover;
      border-radius: 50%;
      border: 5px solid white;
      width: 115px;
      height: 115px;
    }

    & > footer {
      background: #004F84;
      height: 25px;
      text-align: right;
      color: #FFF;
      position: relative;
      display: flex;
      justify-content: flex-end;

      button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .listVisitor-plusBtn {
        background: #6BAF25;
        height: 25px;
        width: 25px;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 35px;
        height: 35px;
        border-width: 1px;
        position: absolute;
        left: 10%;
        top: -90%;
      }
    }

    .rank {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;

      svg {
        width: 25px;
      }
    }

    .favorite {
      margin-left: 15px;
      margin-top: 5px;

      svg {
        width: 25px;
        cursor: pointer;
      }
    }
  }

  .listVisitor-identity {
    display: grid;
    grid-template-columns: 115px 1fr;
    grid-column-gap: 15px;
    padding: 10px;
  }

  .listVisitor-picture {
    margin-bottom: 30px;
    width: 100%;
  }

  .listVisitor-profilDetail {
    margin-right: 15px;
    position: relative;
  }

  .listVisitor-societyName {
    font-weight: bold;
    line-height: 1.2rem;
  }

  .listVisitor-societyName, .listVisitor-lastname {
    text-transform: uppercase;
  }

  .listVisitor-society {
    font-size: 11px;
    font-weight: normal;
    color: #566674;
  }

  .listVisitor-country {
    position: absolute;
    bottom: -10px;
    color: #99A8B4;
    text-transform: uppercase;
    font-weight: 700;
  }

  .modal {
    display: none;
  }

  .navigation nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;

    & > span, & > a {
      width: 25px;

      &::after {
        content: '.';
      }

      &.current {
        font-weight: bold;
      }
    }

    & > span:first-child, & > a:first-child,
    & > span:last-child, & > a:last-child {
      color: white;
      display: flex;
      align-items: center;
      height: 100%;
      overflow: hidden;

      &::before, &::after {
        color: black;
        width: 35px;
      }
    }

    & > span.disabled:first-child, & > a:first-child {
      &::before {
        content: '<';
      }

      &::after {
        content: '';
      }
    }

    & > a:last-child::after, & > span.disabled:last-child::after {
      content: '>';
    }
  }

  .infoBubble {
    width: 25px;
    height: 25px;
    position: absolute;
    left: 4%;
    top: -60%;
    background: $profil-blue-dark;
    border-radius: 50%;
  }
}

#scheduleList {
  .scheduleList-identity {
    background: $profil-gray-blue-light;
    display: flex;
    color: white;
    align-items: center;
    padding: 10px 15px;
  }

  .scheduleList-picture {
    width: 25%;
    margin-right: 15px;
    justify-content: center;

    img {
      height: 85%;
      border-radius: 50%;
      border: 2px solid white;
    }
  }

  .scheduleList-societyName {
    font-weight: bold;
    line-height: 1.2rem;
  }

  .scheduleList-societyName, .listVisitor-lastname {
    text-transform: uppercase;
  }

  .scheduleList-society {
    font-size: 11px;
    font-weight: normal;
    color: #9398a0;
  }

  show-message{
    cursor: pointer;
  }
}

dt {
  font-weight: 600;
}

#meeting-refusal-modal {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;

  .modalContainer {
    min-height: 400px;
    max-width: 600px;
    background: #FFF;
    align-self: center;
    width: 100%;
    padding: 15px;
    border: 1px solid var(--border);
  }
  .actions{
    margin-top: 10px;
  }
}

// #listVisitor-modal {
//   position: fixed;
//   z-index: 1;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100vh;
//   background: rgba(255, 255, 255, 0.3);
//   display: flex;
//   justify-content: center;

modal-dialog {
      width: 100%;
      height: 100vh;
    
//   .planning-modalContainer {
//     position: relative;
//     width: 80%;
//     max-width: 1140px;
//     height: 100vh;
//     max-height: 700px;
//     background: white;
//     overflow: hidden;

    .modal-box {
        width: 80%;
        max-width: 1140px;
        height: 100vh;
        max-height: 700px;

    .modalClose {
      position: absolute;
      top: 0;
      right: 0;
      background: #599220;
      color: white;
      font-weight: bold;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 5;
      cursor: pointer;
    }
  }

  .modal-body {
    display: grid;
    grid-template-columns: 360px 1fr;
    grid-template-rows: 1fr;
    height: 100%;
  }

  .modal-left {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 44% 1fr 16.5%;

    .userProfil {
      min-height: 311px;
    }

    .userProfil, .country {
      background: green;
      color: white;
      padding: 10px 30px;
    }

    p {
      padding: 0;
      margin: 0;
    }

    .user-picture {
      text-align: center;

      img {
        object-fit: cover;
        width: 175px;
        height: 175px;
        border-radius: 50%;
        border: 5px solid white;
      }
    }

    .society {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 30px;

      & > div:last-child {
        width: 100%;

        p:first-child {
          font-size: 0.8rem;
        }
      }

      .logo-society {
        text-align: center;

        img {
          max-width: 60%;
        }
      }
    }

    .country {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 35px;
        height: 25px;
      }
    }
  }

  .modal-right {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 18%;
    margin: 10% 10px 0;

    .social {
      position: absolute;
      top: 10px;
      left: 38%;
      display: flex;

      .btn-social {
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            object-fit: unset !important;
            border-radius: 0 !important;
            border: none !important;
            width: inherit !important;
            height: inherit !important;
        }
      }
    }

    .modal-rightTop {
      display: block;
      min-height: 50px;
      border-bottom: 1px solid green;
      align-items: flex-end;
      padding-bottom: 15px;
      position: relative;
      margin-bottom: 10px;
      overflow: hidden;

    }

    .modal-rightMiddle {
      margin-bottom: 10px;
    }

    .modal-rightFooter {
      background: #004F84;
      color: white;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
      width: 100%;
      height: 123px;

      & > div:last-child {
        margin-left: 10px;
      }
    }

    .media {
      margin-left: 10px;
    }

    video {
      max-width: 100%;
    }
  }

  .modal-rightFooter {
    .btnValidMeeting {
      display: flex;
      justify-content: flex-end;

      a,refusal-action {
        display: flex;
        justify-content: space-between;
        margin-right: 15px;

        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

#planningCalendar {
  header {
    background: $profil-gray-light;
    height: 42px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin-bottom: 10px;

    p {
      margin: 0 10px;
      padding: 0;
    }

    .prevPlanning, .nextPlanning {
      background: white;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-content: center;
    }

  }

  #calendar {
    font-size: 0.8rem;

    a, refusal-action, button {
      color: inherit;
      border: 0 none;
      border-radius: 0;
    //   padding-left: 10px;
      position: relative;
      height: 20px;
      text-transform: uppercase;
    }

    .status {
      color: $profil-gray-dark;
      text-transform: uppercase;
    }

    .available {
      background: $profil-available;
    }

    .blocked {
      background: repeating-linear-gradient(-60deg, #F8DAE0, #F8DAE0 5px, #EDA7AE 5px, #EDA7AE 10px);

      .status {
        color: #D2181E;
      }
    }

    .reserved {
      background: $profil-gray-light;
      font-size: 12px;
      display: flex;
      align-items: center;

      .planningFullName {
        font-weight: bold;
        margin-left: 10px;
      }
    }

    .lunch {
      background: repeating-linear-gradient(-60deg, #E9EAEB, #E9EAEB 5px, #E3E4E6 5px, #E3E4E6 10px);
      height: 100%;
    }

    .empty {
      background: $profil-empty;
    }

    .rightButton {
      position: absolute;
      right: 0;
      top: 0;
      height: 20px;
      display: flex;
      justify-content: left;

      &.twoBtn {
        width: 209px;
      }

      &.reservedButton {
        width: 159px;
        text-align: right;
      }

      & > div {
        display: flex;
      }
    }

    info-bubble {
      position: relative;
      background: $profil-blue-dark;
      width: 25px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .infoBubble {
        color: white;
        width: 14px;
      }

      .infoBubble-message {
        position: absolute;
        width: 200px;
        text-align: center;
        padding: 0 5px;
        color: black;
        background: $profil-gray-dark;
        top: 0;
        right: 50px;
        z-index: 9999;
        display: block;
        font-style: italic;
        font-size: 0.8rem;
      }
    }
    .planningFullName{
      info-bubble {
        display: inline-block;
        line-height: 25px;
        margin-left: 15px;
      }
    }
    .pending{
      color: white;
      background: $profil-blue-dark;
      .rightButton{
        text-transform: uppercase;
        a:first-child{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        svg{
          color: white;
          width: 15px;
          margin-left: 5px;
          &:first-child{
            margin-right: 5px;
          }
        }
      }
    }
    thead tr:first-child {
      display: none;
    }

    table, td, tr {
      border: 0 none;
    }
  }
}

#myFavorite {
  ul {
    li {
      background: #a8a8a8;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: white;
      padding: 15px 20px;
      margin-bottom: 10px;

      show-details{
        display: inline-block;
        background: $profil-blue-dark;
        border-radius: 15px;
        padding: 2px 5px 0;
        height: 25px;
        svg{
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

#myPending, #my-pending, #my-accepted, #my-refused {
  h3 {
    text-transform: capitalize;;
  }
  .pendingEvent {
    .fc-list-event-title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      .btn-meeting{
        display: flex;
        align-items: center;
        .btn-warning, .btn-info{
          background: #c7806d;
          text-decoration: none !important;
          padding: 9px 16px;
          border: none;
          display: inline-flex;
          align-items: center;
          box-shadow: 0 1px 2px rgb(36 57 141 / 40%);
          border-radius: 4px;
          line-height: 1.6;
          font-family: arial, sans-serif;
          font-weight: bold;
          color: #ffffff;
          cursor: pointer;
          transition: filter 0.3s, background 0.3s, color 0.3s;
          font-size: 0.9em;
          filter: brightness(1);
          white-space: nowrap;
        }
        .btn-info{
          background: $profil-blue-dark;
          padding: 10px 10px 10px 5px;
          margin-right: 15px;
        }
        svg{
          width: 15px;
          margin-left: 5px;
        }
        a,refusal-action,button{
          height: 25px;
          color: black;
        }
      }
      &>div:first-child{
        display: flex;
      }
    }
  }

  info-bubble {
    position: relative;
    background: $profil-blue-dark;
    width: 25px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;

    .infoBubble {
      color: white;
      width: 14px;
    }

    .infoBubble-message {
      position: absolute;
      width: 200px;
      text-align: center;
      padding: 0 5px;
      color: black;
      background: $profil-gray-dark;
      top: 0;
      left: 30px;
      z-index: 9999;
      display: block;
      font-style: italic;
      font-size: 0.8rem;
    }
  }
}

.btn-planning {
  text-align: right;
  color: white;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: right;

  &.planningBlockedButton {
    width: 36px;
    height: 20px;
    background: #D2181E url('/assets/images/icons/blocked.svg') no-repeat center center;
  }

  &.planningOpenButton {
    width: 36px;
    height: 20px;
    background: #31d218 url('/assets/images/icons/calendar-check-solid.svg') no-repeat center center;
  }

  &.planningReSchedule {
    width: 36px;
    height: 20px;
    fill: white;
    background: #6a6a6a url('/assets/images/icons/repeat.svg') no-repeat center center;
  }

  &.planningCallButton {
    width: 36px;
    height: 20px;
    fill: white;
    background: #6BAF25 url('/assets/images/btn_call.png') no-repeat center center;
  }

  &.planningNoShowButton {
    width: 36px;
    height: 20px;
    fill: white;
    background: #252D3F url('/assets/images/btn_no_show.png') no-repeat center center;
    font-size: 0.8rem;
  }

  &.planningDeleteSchedule {
    width: 36px;
    height: 20px;
    fill: white;
    background: #c97908 url('/assets/images/icons/calendar_delete.svg') no-repeat center center;
  }

  &.showDetails{
    background: $profil-blue-dark;
    width: 25px;
    height: 20px;
    justify-content: center;
    svg{
      width: 15px;
    }
  }
}
#planningCalendar #calendar .reserved .btn-planning.showDetails a{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
.fc .fc-timegrid-slot-label {
  background: $profil-gray-light;
}

.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0 0 0 5px !important;
}

.fc-event-main {
  display: none;
}

#listOfMeeting {
  table {
    width: 100%;

    tr:nth-child(2n+1) {
      background: #E5EDF2;
    }
    td:nth-child(2n){
      font-size: .8rem;
    }
    a,button,show-message {
      color: #376793;
      cursor: pointer;
    }
  }
}

@media (max-height: 700px) and (min-width: 1000px) {
  .planning-intro {
    padding-top: calc(var(--padding) * .6);
  }
}

@media (min-height: 900px) {
  #listVisitor-modal {
    align-items: center;

    .listVisitor-modalContainer {
      height: 80vh;
      align-items: center;
    }
  }
}

@include up(1000) {
  .planning-contain {
    max-width: 1250px;
    margin: 325px auto 0;
  }
  .planning-intro {
    position: relative;
    display: grid;
    grid-template-columns: 274px 1fr;
    width: 100%;
    margin: 10px auto 0;
  }
  .planning-mainHeader {
    width: 60%;

    .planning-userProfil {
      .planning-userPicture {
        margin-top: 8px;
      }
    }

    footer > div {
      width: 70%;
      padding-right: 15px;
    }
  }

  .planning-sidebar {
    position: static;
    width: 100%;
    height: auto;

    ul {
      overflow: hidden;
    }

    .planning-sidebar-displayBar {
      display: none;
    }
  }
  #listVisitor {
    .listVisitor-cards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 5px;
    }
  }
}

// CONTENT // [JMS 2021.09.14]


@include down(1200) {
    .panning-chart {
        display: block;
        height: auto;
    
        > * {
            margin-top: 5px;
        }
    }

    #planningCalendar #calendar .fc-event:not(.lunch) {
        height: auto !important;
        display: grid;
        grid-template-columns: 25% 54% 20%;
        place-items: normal;
    }

    .appointment_awaiting_validation {
        display: none !important;
    }

    #planningCalendar #calendar .rightButton {
        position: relative !important;
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        justify-content: flex-end !important;
    }

    #listVisitor-modal .planning-modalContainer {
        height: 80vh;
        margin: auto;
    }

    #listVisitor-modal .modal-left .society {
        margin-top: 25px;
    }

    #listVisitor-modal .modal-body {
        display: block;
    }
}

.img-ag-labled { padding: 40px; }

.ag-handicap-label-icon {
  margin-right: 8px;
  .handicap_auditif.handicap_moteur {
    background-image: url("../../images/handicaps/RVB-mini_AM.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 30px;
  }
  .handicap_auditif.handicap_mental {
    background-image: url("../../images/handicaps/RVB-mini_AMe.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 30px;
  }
  .handicap_auditif.handicap_visuel {
    background-image: url("../../images/handicaps/RVB-mini_AV.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 30px;
  }
  .handicap_mental.handicap_moteur {
    background-image: url("../../images/handicaps/RVB-mini_MeM.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 30px;
  }
  .handicap_mental.handicap_visuel {
    background-image: url("../../images/handicaps/RVB-mini_MeV.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 30px;
  }
  .handicap_moteur.handicap_visuel {
    background-image: url("../../images/handicaps/RVB-mini_MV.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 30px;
  }
  .handicap_auditif.handicap_mental.handicap_moteur {
    background-image: url("../../images/handicaps/RVB-mini_AMeM.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 30px;
  }
  .handicap_auditif.handicap_mental.handicap_visuel {
    background-image: url("../../images/handicaps/RVB-mini_AMeV.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 30px;
  }
  .handicap_auditif.handicap_moteur.handicap_visuel {
    background-image: url("../../images/handicaps/RVB-mini_AMV.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 30px;
  }
  .handicap_mental.handicap_moteur.handicap_visuel {
    background-image: url("../../images/handicaps/RVB-mini_MeMV.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 30px;
  }
  .handicap_auditif.handicap_mental.handicap_moteur.handicap_visuel {
    background-image: url("../../images/handicaps/RVB-mini_AMeMV.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 121px;
    height: 30px;
  }
}

.durable-approach-icon {
  background-image: url("../../images/handicaps/developpement-durable.jpg");
  background-size: contain;
  width: 100px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  //background-color: white;
}

.qualite-tourisme-icon {
  background-image: url("../../images/handicaps/qualite-tourisme-francia.jpg");
  background-size: contain;
  width: 100px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
}

.d-flex {display: flex}


button.btn-dm{
  background: #3c4a65;
  padding: 10px 20px;
  border: 1px solid #294d8e;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-content: center;
  align-items: center;
  color: #FFF;
  transition: all 0.3s;
  position: relative;
}
button.btn-dm.active {
  background: #c32023;
}


button.btn-dm span {
  transition: all 0.3s;
}
button.btn-dm::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: rgba(255,255,255,0.5);
  border-bottom-color: rgba(255,255,255,0.5);
  transform: scale(0.1, 1);
}
button.btn-dm:hover span {
  letter-spacing: 2px;
}
button.btn-dm:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}
button.btn-dm::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255,255,255,0.1);
}
button.btn-dm:hover::after {
  opacity: 0;
  transform: scale(0.1, 1);
}

.user-notications-container {
  width: 100%;
  text-align: right;
}
.user-notications-container > div.user-notications-list.show {
  display: block !important;
}
.planningPage div.user-notications-list {
  right: auto !important;
  top: 25px;
}
.user-notications-container > div.user-notications-list {
  background: #fff;
  border: 1px solid #26447b;
  color: #26447b;
  display: none;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  right: 4%;
  top: 10vh;
  width: 40vw;
  z-index: 99;
  text-align: left;
}

close-modal{
  background: var(--contrast);
  color: #fff;
  border-radius: 50px;
  padding: 2px 6px;
  margin: 4px 2px;
  float: right;
  cursor: pointer;
  position: absolute;
  right: 20px;
  z-index: 9999;
}