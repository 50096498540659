.calendar .calendar-schedule {
    background-color: #eaecee;
    height: 45px;

    &.lunch {
        background: repeating-linear-gradient(-60deg, #e9eaeb, #e9eaeb 5px, #e3e4e6 0, #e3e4e6 10px);
        height: 135px;
    }

    &.available {
        background: #f0f7e9;
        background: var(--color-inversed);
    }

    &.empty {
        background: #f6e5cc;
    }

    &.blocked {
        background: repeating-linear-gradient(-60deg, #f8dae0, #f8dae0 5px, #eda7ae 0, #eda7ae 10px);
    }
}

.calendar .calendar-schedule .schedule__hour {
    background-color: #eaecee;
}