.accordion {
    max-width: 100%;

    .accordion__section {
        position: relative;
        margin: 10px 20px;

        .accordion__section_title {
            position: relative;
            margin: 0;
            padding: 10px;
            cursor: pointer;

            &::before {
                content: '+';
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
            }
        }

        &.active .accordion__section_title::before {
            content: '-';
        }
        
        .accordion__section_content {
            position: relative;
            padding: 0 20px;
            max-height: 0;
            overflow: hidden;
            transition: 0.3s ease-out;
        }
    }
}