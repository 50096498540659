.language-switcher {
    border: 2px solid var(--border);
    padding: space(0.5);
    border-radius: 8px;
}

.language-switcher li {
    padding: space(0.5);
    &:not(:last-child) {
        border-right: 2px solid var(--border);
    }
}

.language-switcher li a {
    opacity: .6;
    font-weight: lighter;
    &.active {
        opacity: 1;
        font-weight: bold;
    }
}

.is-open .language-switcher {
    display: none;
}