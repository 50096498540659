// Footer
// =============
.footer {
  @extend %container;
  /* Suppression backgroung footer [JMS 2021.09.14] */
  height: 400px;
  height: 900px;
  padding-top: space(15);
  padding-bottom: space(6);
  //background: var(--background); // linear-gradient(#00000003, #00000003);
  /* margin-top: auto; */
  /* fin Suppression backgroung footer */
  /* JMS [2021.09.14] border-top: solid 4px var(--border-light);*/
  grid-gap: space(4);
  bottom: 0px;
}

.footer p {
  color: black;
  font-size: 1.0rem;
  text-align: right;
}

.footer ul li {
  margin-bottom: space(.5);
  color: var(--color-light)
}

.footer li a {
  display: flex;
  align-items: center;
}

.footer li a .icon {
  margin-top: -2px;
  width: 17px;
  height: 17px;
  margin-right: space(.5);
}

.footer a:hover {
  color: var(--contrast);
}

@include up(800) {
  .footer {
    justify-content: space-between;
    display: grid;
    /* grid-template-columns: 440fr 375fr 200fr;*/
    grid-template-columns: 1fr 350px;
  }
}


// MOBILES 
// ===================================

@media screen and (max-width: 550px) {

  .footer {
    height: 900px;
  }

  .footer p {
    text-align: left;
    width: 80vw;
    margin: auto;
  }

}